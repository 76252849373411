import $ from "jquery";
import Swal from "sweetalert2/dist/sweetalert2.min";
import img1 from "../images/Griego-128.png";
import img2 from "../images/Evangelio-128.png";
import img3 from "../images/Genesis-128.png";

$(document).on("turbolinks:load", () => {
  const libro = sessionStorage.getItem("libro") || "";
  if (libro !== "yes") {
    Swal({
      title: "Ya están los libros en venta online",
      html: `<p style="display: flex; margin: 1rem 0;"><img src="${img1}" /><img src="${img2}" /><img src="${img3}" /></p>
          Para más información haga click <a href="/pages/libros" target="_top">Acá</a>
          `,
      type: "info",
    });
    sessionStorage.setItem("libro", "yes");
  }
});
